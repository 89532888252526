import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { catchError, filter, of, switchMap, take, takeWhile, throwError, timer } from 'rxjs';
import { AvailabilityModalComponent } from 'src/app/shared/components/availability-modal/availability-modal.component';
import { DayChangeModalComponent } from 'src/app/shared/components/day-change-modal/day-change-modal.component';
import { EditMatterBudgetPopupComponent } from 'src/app/shared/components/edit-matter-budget-popup/edit-matter-budget-popup.component';
import { LongRunningTimerModalComponent } from 'src/app/shared/components/long-running-timer-modal/long-running-timer-modal.component';
import { ManualRequestModalComponent } from 'src/app/shared/components/manual-request-modal/manual-request-modal.component';
import { TimerPopUpModalComponent } from 'src/app/shared/components/timer-pop-up-modal/timer-pop-up-modal.component';
import { Config } from 'src/app/shared/constants/constants';
import { DisplayActions, Permissions, Roles, TimerErrorCodes, TimerStatus } from 'src/app/shared/enums/enums';
import { ValidationMessages } from 'src/app/shared/enums/messages';
import { StopTimerRequest } from 'src/app/shared/interfaces/Admin/AutomaticTimeEntry/StopTimerRequest';
import { Timer } from 'src/app/shared/interfaces/Admin/AutomaticTimeEntry/TimerResponse';
import {
  MatterClientList,
  MatterClientListForAutomaticTimesheetList,
} from 'src/app/shared/interfaces/Admin/HJHours/MatterClientListResponse';
import { DashBoard, DashBoardDataResponse } from 'src/app/shared/interfaces/Admin/TempTimeHours/DashBoardDataResponse';
import {
  AvailabilityStatus,
  AvailabilityStatusListResponse,
} from 'src/app/shared/interfaces/Admin/User/AvailabilityStatusListResponse';
import { LocalPermissions } from 'src/app/shared/interfaces/LocalPermissions';
import {
  EMPTY_SOLICITORS_DASHBOARD_API_RESPONSE,
  SolicitorsDashboardModel,
} from 'src/app/shared/interfaces/Solicitors-Dashboard/SolicitorsDashboardResponse';
import { AuthService, RoleService, UserService } from 'src/app/shared/services';
import { SolicitorsDashboardService } from 'src/app/shared/services/solicitors-dashboard.service';
import { TimeLogService } from 'src/app/shared/services/time-log.service';
import { TimesheetService } from 'src/app/shared/services/timesheet.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Permission } from '../../shared/interfaces/Admin/Role/RoleDetailsByRoleIdResponse';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, AfterViewInit {
  currentIndex = -1;
  billableHours = 0;
  hJHours = 0;
  tempTimeHrs = 0;
  firstLoadFlag = true;
  isAdmin = false;
  isLoading = false;
  intervalTime = 60000;
  matterIDForEditMatterBudgetDirectly = -1; // If query params include matter id then it will open edit matter budget popup directly for Matter budget threshold functionality otherwise it will work normally

  currentUserId: number;
  currentTimeString: string;

  availabilityStatusList: AvailabilityStatus[];
  selectedAvailability: number;
  selectedAvailabilityText: string;

  nonBillableClientId: number;

  isTimerRunning = false;
  currentTimer: null | Timer;

  localPermission: LocalPermissions;
  timesheetPermission: LocalPermissions;
  alertLongRunningTimerRef: NgbModalRef | null;

  private destroyRef = inject(DestroyRef);
  validationMessages = ValidationMessages;
  isEditedNewTimeInvalid = false;
  isEditedNewTimeExceeded = false;

  matterClientListAutomaticTimesheet: MatterClientListForAutomaticTimesheetList[];

  // Utilisation Percentage
  utilisationData = signal<SolicitorsDashboardModel[]>([]);
  currentMonth = new Date().getMonth() + 1;
  currentYear = new Date().getFullYear();

  constructor(
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _userService: UserService,
    private _timeSheetService: TimesheetService,
    private _roleSevice: RoleService,
    private _utils: UtilsService,
    private _timeLogService: TimeLogService,
    private toastrService: ToastrService,
    private solicitorsDashboardService: SolicitorsDashboardService,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) {
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.isAdmin = userInfo.roleTypeName === Roles.administrative;
      this.currentUserId = userInfo.userId;
    }

    this.getDashboardData(this.firstLoadFlag, false).then(() => (this.firstLoadFlag = false));

    if (!this.isAdmin) {
      this.getAvailabilityStatus();
    }
  }

  ngOnInit(): void {
    this._roleSevice.allObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((permissions) => {
      this.setPermissions(permissions);
    });

    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('billablehour')) this.currentIndex = 1;
        else if (event.url.includes('hjhours')) this.currentIndex = 2;
        else if (event.url.includes('temptimehour')) this.currentIndex = 3;
        else this.currentIndex = -1;
      });
    this.subscribeActivatedRoute();
    this.loadUtilisationData();
  }

  ngAfterViewInit() {
    this.subscribeCurrentTimeofMatter();
    this.subscribeCurrenTimesheetDetails();
    this.setTimerForDashboardAndUserData();
    this.subscribeDashboardUpdate();
    this.subscribeGetMatterClientList();
  }

  loadUtilisationData() {
    this.solicitorsDashboardService
      .getSolicitorsDashboard(this.currentUserId, this.currentYear, this.currentMonth)
      .pipe(
        catchError((error) => {
          this.toastrService.error(`Error fetching user list: ${error.message}`);
          return of(EMPTY_SOLICITORS_DASHBOARD_API_RESPONSE);
        }),
      )
      .subscribe((response) => {
        if (response?.result) {
          response.result.forEach((element) => {
            element.month = this.currentMonth;
            element.year = this.currentYear;
          });
          this.utilisationData.set(response.result);
        }
      });
  }

  todayUtilisationPercentage = computed(() => {
    const utilisationData = this.utilisationData();
    const todayDate = new Date().toISOString().split('T')[0];

    let utilisationPercentage = 0;

    utilisationData.forEach((solicitor) => {
      solicitor.daily.forEach((entry) => {
        if (entry.date.split('T')[0] === todayDate) {
          utilisationPercentage = entry.utilisation;
        }
      });
    });
    return utilisationPercentage;
  });

  setPermissions(permissions: Permission[]) {
    const setPermission = (permissionName: string, permissions: Permission[]) => {
      const { canView, canCreateUpdate, canDelete, canDownload } = this._utils.getPermissionByName(
        permissions,
        permissionName,
      );
      return {
        canView,
        canCreateUpdate,
        canDelete,
        canDownload,
      };
    };

    if (permissions.length) {
      this.localPermission = setPermission(Permissions.ManualTimesheet, permissions);
      this.timesheetPermission = setPermission(Permissions.Timesheet, permissions);
    }
  }

  subscribeCurrentTimeofMatter() {
    this._timeLogService.currentTimeofMatterObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentTime) => {
        this.currentTimeString = this._timeSheetService.formatTime(currentTime, false);
        this.isTimerRunning = this._timeLogService.isTimerRunning;
      });
  }

  subscribeCurrenTimesheetDetails() {
    this._timeLogService.currenTimesheetDetailsObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((d) => {
      this.currentTimer = d;
    });
  }

  subscribeDashboardUpdate() {
    this._timeSheetService.updateDashboardData.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((canLoad) => {
      this.getDashboardData(canLoad.firstLoad, canLoad.normalLoad);
    });
  }

  subscribeActivatedRoute() {
    this._activatedRoute.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((queryParams) => {
      if (queryParams?.['action'] === DisplayActions.EditBudget) {
        this.cdRef.detectChanges();
        this.matterIDForEditMatterBudgetDirectly = +(queryParams?.['id'] ?? 0);
        this.openBudgetModal();
      }
    });
  }

  subscribeGetMatterClientList() {
    this._timeSheetService.getMatterClientListData.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((canLoad) => {
      canLoad && this.getMatterClientList();
    });
  }

  setTimerForDashboardAndUserData() {
    timer(30000, this.intervalTime)
      .pipe(
        switchMap(() => {
          return this.manageGetDashboardUserData();
        }),
        filter((data) => data !== undefined),
        takeUntilDestroyed(this.destroyRef),
        catchError((error) => {
          return throwError(() => error);
        }),
      )
      .subscribe((data) => {
        if (data?.result) {
          this.processDashBoardData(data.result, this.firstLoadFlag, true).then(() => {
            if (!this.isTimerRunning) {
              this._timeLogService.setcurrentTimesheetDetailsData(null);
              this.currentTimer = null;
            }
          });
          if (this.isTimerRunning && this.currentTimer) {
            this.getTimerDetailsForUser(data?.result?.runningTimerModel?.timesheetId ?? 0);
          }
        }
        // Load utilisation data after dashboard data received
        this.loadUtilisationData();
      });
  }

  setValidation(validationErrros: { timeInvalid: boolean; timeExceed: boolean }) {
    this.isEditedNewTimeInvalid = validationErrros.timeInvalid;
    this.isEditedNewTimeExceeded = validationErrros.timeExceed;
  }

  getNowMatterListData() {
    const timeOut = setTimeout(() => {
      this._timeSheetService.getNowMatterListData();
      clearTimeout(timeOut);
    }, 2000);
  }

  getNowTimesheetListData() {
    const timeOut = setTimeout(() => {
      clearTimeout(timeOut);
      this._timeLogService.onTimeSheetDataChanged();
    }, 2000);
  }

  openHourseList = (navigateTo: string[]) => {
    this._authService.navigateTo([...navigateTo]);
  };

  isLoadingScreen = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  getMatterClientList() {
    this._timeSheetService
      .getMatterClientList({
        userId: this.currentUserId,
        timesheetDate: this._timeSheetService.formatDate(new Date().toDateString()),
      })
      .subscribe({
        next: (res) => {
          if (res.result.matterClients?.length) {
            this.matterClientListAutomaticTimesheet = [];
          }
          this.setMatterClientListAutomaticTimesheet(res.result.matterClients);
          const runningIndex = this.matterClientListAutomaticTimesheet.findIndex((row) => {
            return row.timerStatus && row.timerStatus == 'Running';
          });
          if (runningIndex !== -1) {
            const matterClient = this.matterClientListAutomaticTimesheet[runningIndex];
            if (
              this.isTimerRunning &&
              this.currentTimer &&
              this.currentTimer.timesheetId == this.matterClientListAutomaticTimesheet[runningIndex].timesheetId
            ) {
              this.matterClientListAutomaticTimesheet[runningIndex].isActive = true;
            } else if (this.currentTimer && this.isTimerRunning) {
              this.endTimer(false, runningIndex);
              setTimeout(() => {
                this._timeSheetService.startEndTimerFn(matterClient, true);
              }, 1000);
            } else if (!this.isTimerRunning) {
              this._timeSheetService.startEndTimerFn(matterClient, true);
            }
          } else if (this.isTimerRunning) {
            const i = this.matterClientListAutomaticTimesheet.findIndex((row) => {
              return row.isActive;
            });
            if (i !== -1) {
              const matterClient = this.matterClientListAutomaticTimesheet[i];
              this.endTimer(false, i);
              setTimeout(() => {
                this._timeSheetService.startEndTimerFn(matterClient, true);
              }, 1000);
            } else {
              this._timeLogService.endTime();
              this._timeLogService.setcurrentTimesheetDetailsData(null);
            }
          }
        },
        error: () => {},
      });
  }

  async endTimer(isDashboardEnd: boolean, index: number, isCancelEditTimesheet = false) {
    if (!isDashboardEnd) {
      this.matterClientListAutomaticTimesheet[index].isActive = false;
    }
    if (this.currentTimer) {
      this.isLoading = true;
      this._timeLogService.endTime();

      const date = new Date();
      let offset = date.getTimezoneOffset();
      offset = offset * -1;
      const req: StopTimerRequest = {
        userId: this.currentUserId,
        timesheetId: this.currentTimer.timesheetId,
        epochTimestamp: this.currentTimer.epochTimestamp,
        endTime: new Date().toISOString(),
        isManuallyStopped: true,
        timesheetDate: this._timeSheetService.formatDate(date.toDateString()),
        timezoneOffset: offset,
        timezone: moment.tz.guess(),
      };
      this._timeLogService.endTimer(req).subscribe({
        next: () => {
          this._timeLogService.setcurrentTimesheetDetailsData(null);
          this.currentTimer = null;
          this.getDashboardData(false, false);
          this.getMatterClientList();
          this.getNowMatterListData();
          if (!isCancelEditTimesheet) {
            this.getNowTimesheetListData();
            this.isLoading = false;
          }
        },
        error: () => {
          this.isLoading = false;
          this._timeLogService.endTime();
          this._timeLogService.setcurrentTimesheetDetailsData(null);
        },
      });
    } else {
      this._timeLogService.endTime();
      this._timeLogService.setcurrentTimesheetDetailsData(null);
    }
  }

  setMatterClientListAutomaticTimesheet(matterClients: MatterClientList[]) {
    this.matterClientListAutomaticTimesheet = matterClients.map((row) => ({
      ...row,
      isActive: false,
      timerValue: 0,
      timerString: this._timeSheetService.formatTime(this._timeSheetService.getSeconds(row.totalTime), false),
      isEditEnabled: new Date().toDateString() === new Date(row.timesheetDate).toDateString(),
    }));
  }

  getTimerDetailsForUser(timesheetId: number) {
    this._timeLogService
      .getTimerDetailsForUser({
        userId: this.currentUserId,
        timesheetId: timesheetId || (this.currentTimer?.timesheetId ?? 0),
        lastUpdateTime: new Date().toISOString(),
        systemDate: this._timeSheetService.formatDate(new Date().toDateString()),
      })
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          if (result && result.result.timerStatus === TimerStatus.running) {
            this._timeLogService.setTotaltimeOfDayData(this._timeSheetService.getSeconds(result.result.totalTime));
            this._timeLogService.setTotalBillableAndNonBillableTimeofDay(
              this._timeSheetService.getSeconds(result.result.totalTimeBillableAndNonBillable ?? 0),
            );
            this._timeLogService.setcurrentTimeofMatterData(
              this._timeSheetService.getSeconds(result.result.totalTimeForTimer),
            );
            this._timeLogService.setLastLoggedTimeOfMatterData(
              this._timeSheetService.getSeconds(result.result.lastLoggedTime),
            );
            this._timeLogService.setcurrentTimesheetDetailsData(result.result);

            if (result.result.isTimerRunningLong) {
              this.popupAlertLongRunningTimer(
                result.result.clientName ?? '',
                result.result.matterName ?? '',
                result.result.description ?? '',
              );
            }
          } else if (result && result.result.timerStatus === TimerStatus.stopped) {
            this.currentTimer && this._timeLogService.endTime();
            this.alertLongRunningTimerRef?.dismiss();
            this._timeLogService.setcurrentTimesheetDetailsData(null);
            this.getMatterClientList();
            this.getNowMatterListData();
            this.getNowTimesheetListData();
          }
        },
        error: (err: HttpErrorResponse) => {
          this._timeLogService.endTime();
          this._timeLogService.setcurrentTimesheetDetailsData(null);
          if (err?.error?.statusCode === TimerErrorCodes.DayChanged) {
            this.openDayChangedNotificationTimerPopup();
          }
          this.getNowMatterListData();
          this.getNowTimesheetListData();
        },
      });
  }

  openDayChangedNotificationTimerPopup() {
    const dayChangeModalRef = this._modalService.open(DayChangeModalComponent, {
      windowClass: 'saveconfirmation-modal temphour-modal',
      centered: true,
    });
    dayChangeModalRef.componentInstance.closeModal?.subscribe(() => dayChangeModalRef.close());
    dayChangeModalRef.componentInstance.isLoading?.subscribe((loading: boolean) => (this.isLoading = loading));
    dayChangeModalRef.componentInstance.openHourLogModal?.subscribe(() => {
      this.openTimerPopUpModal();
      dayChangeModalRef.dismiss();
    });
  }

  popupAlertLongRunningTimer(clientName: string, matterName: string, description: string) {
    if (this.alertLongRunningTimerRef) {
      return;
    }
    this.alertLongRunningTimerRef = this._modalService.open(LongRunningTimerModalComponent, {
      windowClass: 'alert-long-running-timer',
      centered: true,
    });
    this.alertLongRunningTimerRef.componentInstance.clientName = clientName;
    this.alertLongRunningTimerRef.componentInstance.matterName = matterName;
    this.alertLongRunningTimerRef.componentInstance.description = description;
    this.alertLongRunningTimerRef.componentInstance.isTimerActivelyRunning = true;
    this.alertLongRunningTimerRef.hidden.pipe(takeWhile(() => this.alertLongRunningTimerRef !== null)).subscribe(() => {
      this.alertLongRunningTimerRef = null;
    });
  }

  manageGetDashboardUserData() {
    const availabilityDate = this._authService.get('loggedInAt');
    if (availabilityDate != null && !this.isAdmin && !this.checkAvailabilityDate(new Date(availabilityDate))) {
      localStorage.removeItem('loggedInAt');
      this.openAvailabilityStatusModal();
    }
    return this.getDashBoardDataForUser().pipe(
      catchError(() => {
        if (!this.isTimerRunning) {
          this._timeLogService.setcurrentTimesheetDetailsData(null);
          this.currentTimer = null;
        }
        return of(undefined);
      }),
    );
  }

  checkAvailabilityDate(date: Date) {
    const endTime = new Date();
    let timeDiff = endTime.getTime() - date.getTime();
    timeDiff /= 1000;
    const seconds = Math.round(timeDiff);
    return seconds <= Config.availabilityPopupInterval;
  }

  processDashBoardData(dashBoardData: DashBoard, firstLoad?: boolean, normalLoad?: boolean) {
    return new Promise<void>((resolve) => {
      if (dashBoardData?.availibilityStatusId) {
        this.selectedAvailability = dashBoardData.availibilityStatusId;
        const temp = this.availabilityStatusList.find((status) => status.id === this.selectedAvailability);
        this.selectedAvailabilityText = temp?.status ?? '';
      }

      const { billableHours, nonBillableHours, tempTimeHours, nonBillableClientId } =
        dashBoardData.timeSheetHoursResponseModel;
      this.billableHours = billableHours;
      this.hJHours = nonBillableHours;
      this.tempTimeHrs = tempTimeHours;

      this._timeLogService.setTotaltimeOfDayData(
        this._timeSheetService.getSeconds(dashBoardData.todaysTotalLogTime ?? 0),
      );
      this._timeLogService.setTotalBillableAndNonBillableTimeofDay(
        this._timeSheetService.getSeconds(dashBoardData.todaysTotalLogTimeBillableAndNonBillable ?? 0),
      );
      this._timeLogService.setIncompleteTimeSheet(dashBoardData.runningTimerModel?.hasIncompleteTimesheet);

      this.nonBillableClientId = nonBillableClientId;

      this._timeSheetService.setUpdatedData(this.nonBillableClientId ?? 0);

      if ((firstLoad && !this.isTimerRunning) || (!firstLoad && normalLoad && !this.isTimerRunning)) {
        if (dashBoardData?.runningTimerModel.timerStatus === TimerStatus.running && !this.isTimerRunning) {
          this.getMatterClientList();
          this._timeLogService.setcurrentTimesheetDetailsData(null);
          this.currentTimer = null;
          resolve();
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  getDashboardData(firstLoad: boolean, normalLoad: boolean) {
    return new Promise<void>((resolve) => {
      this.getDashBoardDataForUser().subscribe({
        next: (res: DashBoardDataResponse) => {
          this.processDashBoardData(res.result, firstLoad, normalLoad).then(() => resolve());
        },
        error: () => {
          this.firstLoadFlag = false;
        },
      });
    });
  }

  getDashBoardDataForUser() {
    return this._timeSheetService.getDashboardData(
      this._timeSheetService.formatDate(new Date().toDateString()),
      this.currentUserId,
    );
  }

  getAvailabilityStatus() {
    this._userService.getAvailibilityStatus().subscribe({
      next: (res: AvailabilityStatusListResponse) => {
        this.availabilityStatusList = res.result;
      },
    });
  }

  openAvailabilityStatusModal() {
    const availibityModalRef = this._modalService.open(AvailabilityModalComponent, {
      windowClass: 'd-block modal new-work-modal fade show',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    _.extend(availibityModalRef.componentInstance, {
      selectedAvailability: this.selectedAvailability,
      availabilityStatusList: this.availabilityStatusList,
    });
    availibityModalRef.componentInstance.editedAvailability?.subscribe((availabilityStatus: string) => {
      this.selectedAvailabilityText = availabilityStatus;
      this._modalService.dismissAll();
    });
  }

  openBudgetModal() {
    const editMatterBudgetRef = this._modalService.open(EditMatterBudgetPopupComponent, {
      windowClass: 'edit-budget-modal manual-request-modal',
      centered: true,
    });
    editMatterBudgetRef.componentInstance.matterIDForEditMatterBudgetDirectly =
      this.matterIDForEditMatterBudgetDirectly;
    editMatterBudgetRef.componentInstance.currentUserId = this.currentUserId;
    editMatterBudgetRef.componentInstance.isAdmin = this.isAdmin;
    editMatterBudgetRef.result.finally(() => {
      this.getNowMatterListData();
    });
  }

  openTimerPopUpModal() {
    this.isLoading = true;
    const timerPopUpModalRef = this._modalService.open(TimerPopUpModalComponent, {
      windowClass: 'hour-log-modal',
      centered: false,
    });

    timerPopUpModalRef.componentInstance.currentUserId = this.currentUserId;
    timerPopUpModalRef.componentInstance.closeModal?.subscribe(() => timerPopUpModalRef.close());
    timerPopUpModalRef.componentInstance.isLoading?.subscribe((loading: boolean) => (this.isLoading = loading));
  }

  openManualRequestModal() {
    this.isLoading = true;
    const manualRequestModalRef = this._modalService.open(ManualRequestModalComponent, {
      windowClass: 'manual-request-modal',
      centered: true,
    });
    manualRequestModalRef.componentInstance.nonBillableClientId = this.nonBillableClientId;
    manualRequestModalRef.componentInstance.closeModal?.subscribe(() => manualRequestModalRef.dismiss());
    manualRequestModalRef.componentInstance.isLoading?.subscribe((loading: boolean) => (this.isLoading = loading));
  }

  get availabilityDotClass() {
    return {
      green: this.selectedAvailabilityText === 'Available',
      red: this.selectedAvailabilityText === 'Not Available',
      yellow: this.selectedAvailabilityText === 'Some Capacity',
    };
  }
}
