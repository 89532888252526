<div class="modal-body">
  <div class="manual-req-header">
    <h6>Edit Matter/Budget</h6>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
      <svg-icon name="modal-close-icon" class="icon"></svg-icon>
    </button>
  </div>
  <form class="edit-budget-form" [formGroup]="editBudgetForm">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="form-field form-field-select">
          <ng-select
            [items]="clientListEditMatterBudget"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="clientName"
            bindValue="id"
            placeholder="Select Client"
            [virtualScroll]="true"
            [(ngModel)]="selectedClientMatterBudget"
            [ngModelOptions]="{ standalone: true }"
            (change)="clientSelected()"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="form-field form-field-select">
          <ng-select
            [items]="matterListEditMatterBudget"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="matterName"
            bindValue="id"
            placeholder="Select Matter"
            [virtualScroll]="true"
            [(ngModel)]="selectedMatterMatterBudget"
            [ngModelOptions]="{ standalone: true }"
            (change)="matterSelected($event)"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row matter-active-service-plan-section">
      <div class="col-xs-12 col-md-6 service-plan-name">
        <span class="label-text">Service Plan</span>
        <p>{{ servicePlanNameOfMatter || '--' }}</p>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="form-field form-field-select">
          <mat-slide-toggle
            [checked]="isMatterActive"
            (change)="onMatterStatusChanged($event)"
            [disabled]="(!isAdmin && !isMatterActive) || (isAdmin && !editMatterPermissionForAdmin?.canCreateUpdate)"
            color="primary"
            >Active</mat-slide-toggle
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h6>Current Budget</h6>
        <div class="blue-box">
          <div class="row row-top">
            <div class="col-sm-2 border-r">
              <span class="label-text">Solicitor Hours</span>
              <p>{{ currentBudget.solicitorHours || 0 }} Hrs</p>
            </div>
            <div class="col-sm-3 border-r">
              <span class="label-text">Legal Assistant Hours</span>
              <p>{{ currentBudget.legalAssistantHours || 0 }} Hrs</p>
            </div>
            <div class="col-sm-3 border-r">
              <span class="label-text">Solicitor Hourly Rate</span>
              <p>{{ (currentBudget.solicitorHourlyRate || 0 | decimalPlaces) || 0 | sysCurrency }}</p>
            </div>
            <div class="col-sm-4">
              <span class="label-text">Legal Assistant Hourly Rate</span>
              <p>{{ (currentBudget.legalAssistantHourlyRate || 0 | decimalPlaces) || 0 | sysCurrency }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-7 border-r">
              <span class="label-text">Budget in Hours</span>
              <p>{{ currentBudget.budgetInHours || 0 }} hrs</p>
            </div>
            <div class="col-sm-5">
              <span class="label-text">Total Budget Amount</span>
              <p>{{ currentBudget.totalBudgetAmount || 0 | sysCurrency }}</p>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-xs-12 col-md-12 add-budget-notes">
            <a (click)="onAddNotes()"
              [ngClass]="{ 'add-notes-restricted': !(selectedClientMatterBudget && selectedMatterMatterBudget) }">
              Send Notes
            </a>
          </div>
        </div> -->
        <div class="row">
          <div class="col-sm-12">
            <h6>New Budget</h6>
            <div class="row">
              <div class="col-sm-6 new-budget-col">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-field">
                      <label for="solicitorHours">Solicitor Hours</label>
                      <input
                        matInput
                        type="text"
                        id="solicitorHours"
                        formControlName="solicitorHours"
                        placeholder="0"
                        (input)="onSolicitorHoursFieldInputChanged('solicitorHours')"
                        name="solicitorHours"
                        (change)="validate('solicitorHours')"
                        (keyup)="hoursChanged()"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-field">
                      <label for="legalAssistantHours">Legal assistant Hours</label>
                      <input
                        matInput
                        type="text"
                        id="legalAssistantHours"
                        formControlName="legalAssistantHours"
                        (input)="onSolicitorHoursFieldInputChanged('legalAssistantHours')"
                        (change)="validate('legalAssistantHours')"
                        placeholder="0"
                        name="legalhours"
                        (keyup)="hoursChanged()"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-field">
                      <span class="label-text">Budget in Hours</span>
                      <p>{{ editBudgetForm.controls['budgetInHours'].value || 0 }} Hrs</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-field">
                      <span class="label-text">Change in Amount</span>
                      <p>
                        {{
                          (editBudgetForm.controls['changeInAmount'].value || 0 | decimalPlaces) || 0 | sysCurrency
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="blue-box blue-box-small">
                  <span class="label-text">Hourly rates</span>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-field">
                        <span class="label-text">Solicitors</span>
                        <p>{{ (currentBudget.solicitorHourlyRate || 0 | decimalPlaces) || 0 | sysCurrency }}</p>
                      </div>
                    </div>
                    <div class="col-sm-9">
                      <div class="form-field">
                        <span class="label-text">Legal assistants/trainee solicitors</span>
                        <p>{{ (currentBudget.legalAssistantHourlyRate || 0 | decimalPlaces) || 0 | sysCurrency }}</p>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-field marginb-0">
                        <span class="label-text">Total Budget Amount</span>
                        <p>
                          {{
                            (editBudgetForm.controls['totalBudgetAmount'].value || 0 | decimalPlaces) || 0
                              | sysCurrency
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ************** -->
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <button
          type="button"
          class="btn time-entry-btn"
          (click)="editBudget()"
          [disabled]="
            isBudgetUpdating ||
            !selectedMatterMatterBudget ||
            (!currentBudget.isActive && selectedMatterMatterBudget && !isMatterActive)
          "
        >
          Save
        </button>
      </div>
      <div class="col-xs-12 col-md-6">
        <button type="button" class="btn time-entry-btn btn-outline" (click)="activeModal.dismiss()">Cancel</button>
      </div>
    </div>
  </form>
</div>

<div class="loader-animation" *ngIf="isLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
