<div class="modal-body">
  <div class="manual-req-header">
    <h6>Send Notes</h6>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
      <svg-icon name="modal-close-icon" class="icon"></svg-icon>
    </button>
  </div>
  <div class="insert-budget-notes-form">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="form-field form-field-select">
          <ng-select
            [items]="clientListDatasource"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="clientName"
            bindValue="id"
            placeholder="Select Client"
            [virtualScroll]="true"
            [(ngModel)]="clientID"
            [disabled]="true"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="form-field form-field-select">
          <ng-select
            [items]="matterListDataSource"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="matterName"
            bindValue="id"
            placeholder="Select Matter"
            [virtualScroll]="true"
            [(ngModel)]="matterID"
            [disabled]="true"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-12" [formGroup]="sendNotesForm">
        <div class="form-field form-field-select desc-input">
          <label for="notes">Notes</label>
          <textarea
            matInput
            formControlName="notes"
            id="notes"
            placeholder="Enter Notes"
            name="notes"
            rows="5"
          ></textarea>
          <svg-icon name="matter-icon" class="input-icon"></svg-icon>
          <mat-error
            *ngIf="
              sendNotesForm.controls.notes.touched &&
              sendNotesForm.controls.notes.hasError('descriptionRequired') &&
              !sendNotesForm.controls.notes.hasError('invalidDescription')
            "
            >{{ validationMessages.descriptionRequired }}
          </mat-error>
          <mat-error
            *ngIf="
              sendNotesForm.controls.notes.touched &&
              sendNotesForm.controls.notes.hasError('limitExceeds') &&
              !sendNotesForm.controls.notes.hasError('invalidDescription')
            "
            >{{ validationMessages.maxLength2000ForDescription }}
          </mat-error>
          <mat-error
            *ngIf="
              sendNotesForm.controls.notes.touched &&
              !(
                sendNotesForm.controls.notes.hasError('limitExceeds') ||
                sendNotesForm.controls.notes.hasError('descriptionRequired')
              ) &&
              sendNotesForm.controls.notes.hasError('invalidDescription')
            "
            >{{ validationMessages.allowedCharactersForDescription }}
          </mat-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <button
          type="button"
          class="btn time-entry-btn"
          (click)="sentNotes()"
          [disabled]="sendNotesForm.invalid || !(clientID && matterID)"
        >
          Send Notes
        </button>
      </div>
      <div class="col-xs-12 col-md-6">
        <button type="button" class="btn time-entry-btn btn-outline" (click)="activeModal.dismiss()">Cancel</button>
      </div>
    </div>
  </div>
</div>
