export enum ResponseCodes {
  AccessDenied = 403,
  UnAuthorized = 401,
  NotReachable = 503,
  Success = 200,
  BadRequest = 400,
}

export enum KeyBoardEventCode {
  Backspace = 'Backspace',
  Delete = 'Delete',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}

export enum ClientLoginMode {
  alreadyExist = 1,
  newClient = 2,
  clientNotExist = 3,
}

export enum UserStatus {
  active = 'Active',
  inactive = 'Inactive',
}

export enum StatusIDs {
  draft = 2,
  active = 1,
  inactive = 0,
}

export enum MatterStatus {
  active = 'Active',
  inactive = 'InActive',
  draft = 'Draft',
}

export enum ClientStatus {
  active = 'Active',
  inactive = 'Inactive',
  suspended = 'Suspended',
  paused = 'Paused',
}

export enum BillOnCompletionStatus {
  complete = 'Complete',
  incomplete = 'Incomplete',
}

export enum TimerStatus {
  running = 'Running',
  stopped = 'Stopped',
}

export enum Permissions {
  AllowAccessToTimeSheetReport = 'Allow Access to Timesheet Report',
  Feedback = 'Feedback',
  Timesheet = 'Timesheets',
  BillOnCompletion = 'Bill On Completion',
  Invoices = 'Invoices',
  MatterAdminPermissions = 'Matter-Admin Permissions',
  TimesheetLock = 'Timesheet Lock',
  MatterAddPermissions = 'Add New Matter',
  MatterCompletePermissions = 'Complete Matter',
  ManualTimesheet = 'Manual Timesheet',
  TimesheetReport = 'Timesheet Report',
  Settings = 'Settings',
  OnBoardClient = 'OnBoard Client',
  AddNewClient = 'Add New Client',
  ClientRates = 'Client Rates',
}

export enum PermissionTypeIDs {
  CanView = 1,
  CanCreateUpdate = 2,
  CanDelete = 3,
  CanDownload = 4,
}

export enum PermissionTypes {
  CanView = 'canView',
  CanCreateUpdate = 'canCreateUpdate',
  CanDelete = 'canDelete',
  CanDownload = 'canDownload',
}

export enum TimeSheetTimeEntryTypeIDs {
  Billable = 1,
  NonBillable = 2,
  TempTime = 3,
  WriteOff = 4,
}

export enum DisplayActions {
  EditBudget = 'editBudget',
}

export enum Roles {
  administrative = 'Administrative',
  practiceAreaHead = 'Practice Area Head',
}

export enum RoleTypeId {
  Administrator = 2,
  LegalTeam = 3,
  Client = 4,
}

export enum MemberTypeId {
  PracticeAreaHead = 1,
  Solicitor = 2,
  LegalAssistant = 3,
}

export enum TimerErrorCodes {
  TimeExceededOrTimeSheetLock = 400, // timesheet lock, time exceed
  MatterNotAssigned = 403, // matter not assigned
  DateNotBetweenPlannedStartAndEndDate = 409, // date conflict for not in between plan start and end date
  TimerNoLongerRunning = 300, // isDateChanged -- false -- timer is no longer running
  DayChanged = 406, // isDateChanged -- true -- date changed
  Success = 200, // success
  NoTimeSheetDetailsFound = 404, // no TimeSheet details found
}

export enum ServicePlanIDs {
  onDemand = 1,
  enterprise = 2,
  city = 3,
  extendedOnDemand = 4,
  extendedEnterprise = 5,
  nonClientWork = 6,
}

export enum ServicePlanNames {
  onDemand = 'On-Demand',
  enterprise = 'Enterprise',
  city = 'City',
  extendedOnDemand = 'Extended On-Demand',
  extendedEnterprise = 'Extended Enterprise',
  nonClientWork = 'Non-client work',
}

export enum BusinessReportingMode {
  Edit = 1,
  Add = 2,
}

export enum TimeSheetNavigation {
  WeeklyTimeSheet = 1,
  TimeSheetSummary = 2,
  IncompleteTimeSheet = 3,
}

export enum BusinessReportsHelpSections {
  GenerateEmbedLinkSection = 'generate-embed-link',
  ManageViewInExcelReportSync = 'manage-view-in-excel',
  FAQs = 'faqs',
}

export enum TimesheetReportsDateRanges {
  ThisWeek = 1,
  ThisMonth = 2,
  LastMonth = 3,
}

export enum TimesheetReportsGroupByOptions {
  ClientName = 'ClientName',
  Matter = 'Matter',
  HeadlinePracticeAreaName = 'HeadlinePracticeAreaName',
  Lawyer = 'Lawyer',
  Category = 'Category',
  TimeEntryTypeName = 'TimeEntryTypeName',
  ServicePlanName = 'ServicePlanName',
}

export enum InvoicesCategory {
  WIP = 'WIP',
  Draft = 'Draft',
  Invoices = 'Invoices',
  SentInvoices = 'Sent Invoices',
  SentTimesheets = 'Sent Timesheet',
}

export enum InvoiceServicePlan {
  OnDemand = 'OnDemand',
  Enterprise = 'Enterprise',
  City = 'City',
  ExtendedOnDemand = 'ExtendedOnDemand',
  ExtendedEnterprise = 'ExtendedEnterprise',
  OnDemandTimesheets = 'OnDemandTimesheets',
}

export enum InvoiceActions {
  RaiseAll = 'RaiseAll',
  RaiseSelected = 'RaiseSelected',
  SendAll = 'SendAll',
  SendSelected = 'SendSelected',
}

export enum InvoiceStatus {
  Authorised = 1,
  Deleted = 2,
  Paid = 3,
  Voided = 4,
  Draft = 5,
  Approved = 6,
  Sent = 7,
  Submitted = 8,
}

export enum MediaTypes {
  Zip = 'application/zip',
  Pdf = 'application/pdf',
}
