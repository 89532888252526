import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APIUrls } from 'src/app/shared/enums/apiUrls';
import { ResponseCodes } from 'src/app/shared/enums/enums';
import { AuthService } from 'src/app/shared/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private toastrService: ToastrService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    if (this._authService.isUnderMaintenance && !req.url.includes(APIUrls.CheckServerAvailability)) {
      return EMPTY;
    }
    if (
      req.url.indexOf('/Login') > 0 ||
      req.url.indexOf('/OTPVerify') > 0 ||
      req.url.indexOf('/ForgotPassword') > 0 ||
      req.url.indexOf('/ResendOTP') > 0 ||
      req.url.indexOf('/loader-animation') > 0 ||
      req.url.indexOf('/mail-icon') > 0 ||
      req.url.indexOf('/hide-icon') > 0 ||
      req.url.indexOf('/close-icon') > 0 ||
      req.url.indexOf('/back-arrow') > 0 ||
      req.url.indexOf('/ConfirmEmail') > 0 ||
      req.url.indexOf('/CheckForExistingClient') > 0 ||
      req.url.indexOf('/EndClientLoginOrSignUp') > 0 ||
      req.url.indexOf('/GetUserDetailsByEmail') > 0 ||
      req.url.indexOf('/microsoft') > 0
    ) {
      const contentHeader = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      });
      return next.handle(contentHeader);
    } else {
      let authReq = req;
      const token = this._authService.get('accessToken');
      if (token) {
        authReq = req.clone({ headers: new HttpHeaders({ Authorization: `Bearer ${token}` }) });
      } else if (
        !(authReq.url.includes(APIUrls.CheckServerAvailability) || authReq.url.includes(APIUrls.ValidateJwtToken))
      ) {
        this._authService.logout();
      }
      if (authReq.url.includes(APIUrls.CheckServerAvailability)) {
        authReq = authReq.clone({
          headers: new HttpHeaders({
            'Content-Type': 'text/plain',
          }),
        });
      }
      return next.handle(authReq).pipe(
        map((event: HttpEvent<any>) => {
          if (
            this._authService.isUnderMaintenance &&
            authReq.url.includes(APIUrls.CheckServerAvailability) &&
            event.type
          ) {
            this._authService.setIsSiteUnderMaintenance();
            this._authService.logout();
            const timeout = setTimeout(() => {
              clearTimeout(timeout);
              location.reload();
            }, 2000);
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === ResponseCodes.UnAuthorized) {
            if (error?.error?.message) this.toastrService.error(error.error.message);
            this._authService.logout();
          } else if (error.status === ResponseCodes.AccessDenied) {
            this.toastrService.error('Access Denied');
          } else if (error.status === ResponseCodes.NotReachable) {
            this._authService.underMaintenance();
            this._authService.setIsSiteUnderMaintenance(true);
          }
          return throwError(() => error);
        }),
      );
    }
  }
}
